<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">
    <Snackbar ref="snackbar"/>
    <Confirmation @action-delete="deleted()"/>

    <div class="mt-5">
      <div class="pb-3 d-md-flex justify-space-between">
        <div class="title black--text">List Topic</div>
        <v-btn
          color="#F05326"
          class="white--text text-capitalize"
          depressed
          dense
          @click="$router.push(`/topic/create`)">
          Add New Topic
        </v-btn>
      </div>
      <div class="mr-n3 d-flex flex-wrap" v-if="!$store.state.process.run || searching != ''">
        <v-col cols="12" sm="4" class="pl-0">
          <v-text-field
            placeholder="Search Topic Name"
            color="primary"
            outlined
            dense
            autocomplete="off"
            append-icon="mdi-magnify"
            :hide-details="true"
            v-model="searching.name">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="pl-0">
          <v-autocomplete
            placeholder="Search Topic Type"
            color="primary"
            outlined
            dense
            autocomplete="off"
            :hide-details="true"
            :items="topic_type"
            item-text="text"
            item-value="value"
            v-model="searching.type">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="pl-0">
          <div class="d-flex mr-n3">
            <v-col cols="6" class="pl-0 py-0">
              <v-btn
                color="primary"
                class="text-capitalize"
                height="40"
                depressed
                dense
                block
                @click="getTopicList()">
                Search
              </v-btn>
            </v-col>
            <v-col cols="6" class="pl-0 py-0">
              <v-btn
                color="grey darken-2"
                class="text-capitalize"
                height="40"
                outlined
                dense
                block
                @click="resetTopicList()">
                Reset
              </v-btn>
            </v-col>
          </div>
        </v-col>
      </div>

      <div class="text-center mt-7" v-if="$store.state.process.run == false && list.total < 1">
        <div class="mt-5">
          <v-img :src="require('@/assets/img/empty.png')" width="200" class="mx-auto"></v-img>
        </div>
        <div class="mt-3">No topic found</div>
      </div>

      <div v-if="$store.state.process.run" class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table">
        </v-skeleton-loader>
      </div>

      <div class="mt-3 mb-8" v-if="!$store.state.process.run && list.total > 0">
        <v-data-table
          hide-default-footer
          fixed-header
          class="pa-0"
          :loading="$store.state.process.run"
          :headers="headers"
          :items="list.list"
          :items-per-page.sync="list.limit"
          :page.sync="list.page"
          item-key="id"
          :server-items-length="list.total"
          :footer-props="{
            itemsPerPageText : 'Data per halaman',
            'items-per-page-options': [5,10, 20, 50, 100, 200]
          }"
          >
          <template slot="footer" slot-scope="{ props }">
            <Pagination 
              @reload="getTopicList(page)"
              @changeLimit="limit = $event; getTopicList()" 
              @changePage="page = $event; getTopicList($event)" 
              :model="props">
            </Pagination>
          </template>
          <template v-slot:[`item.topic_type`]="{ item }">
            <v-card width="50" color="blue" class="elevation-0 white--text text-center">
              {{ item.is_paid ? 'Paid' : 'Free' }}
            </v-card>
          </template>
          <template v-slot:[`item.is_pinned`]="{ item }">
            <v-btn
              v-if="item.is_pinned === true"
              small
              color="success"
              class=""
              icon>
              <v-icon>mdi-check-circle</v-icon>
            </v-btn>
            <v-btn
              v-else
              small
              color="error"
              class=""
              icon>
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs ,on }">
                  <v-btn
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="grey darken-1"
                    @click="AddActivity(item)"
                    class="mr-2"
                    icon>
                    <v-icon>mdi-clipboard-outline</v-icon>
                  </v-btn>
                </template>
                <span>Add Activity</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs ,on }">
                  <v-btn
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    color="grey darken-1"
                    :to="`/topic/update/${item.id}`"
                    icon>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit Topic</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs ,on }">
                  <v-btn
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="grey darken-1"
                    @click="confirmation(item.id)"
                    icon>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Topic</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Pagination from '@/components/Pagination.vue'
import Snackbar from '@/components/Snackbar.vue'
import Confirmation from '@/components/Confirmation.vue'

export default {
  components: { Pagination, Snackbar, Confirmation },
  data() {
    return {
      items: [
        { text: '', disabled: false, href: '/course' },
        { text: 'List Topic', disabled: true, href: '/topic' },
      ],
      headers: [
        { text: 'Topic Name', value: 'topic_name', sortable: false },
        { text: 'Week', value: 'week', sortable: false },
        { text: 'Question Type', value: 'type_label', sortable: false },
        { text: 'Total Activity', value: 'total_activities', sortable: false },
        { text: 'Topic Type', value: 'topic_type', sortable: false },
        { text: 'Pinned', value: 'is_pinned', sortable: false },
        { text: 'Action', value: 'action', align: "end", sortable: false}
      ],
      topic_type: [
        { text: 'All', value: '' },
        { text: 'Free', value: '0' },
        { text: 'Paid', value: '1' },
      ],
      list: {},
      searching: {
        name: '',
        type: '',
      },
      id_topic: "",
      pagination: {},
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      process: {
        run: false
      },
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.getTopicList(val+1)
    },
  },
  computed:{},
  created(){
    // this.getDetailCourse()
    this.getTopicList()
  },
  mounted(){},
  methods:{
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    getDetailCourse() {
      get(`api/v1/package/detail/${this.$route.params.id_course}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.items[0].text = res.data.title
        }
      })
    },
    async getTopicList(page) {
      this.$store.state.process.run = true;
      await get("api/v1/topic/list", {
        params: {
          limit: this.limit,
          sort: 'created_at',
          dir: 'desc',
          page: page,
          search_query: this.searching.name,
          is_paid: this.searching.type,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.process.run = false;
          this.list = res.data
        } else {
          this.$store.state.process.run = false;
        }
      });
    },
    resetTopicList() {
      this.limit = 10
      this.page = 1
      this.selected.pagination = 0
      this.searching.name = ''
      this.searching.type = ''
      this.getTopicList(this.page)
    },
    EditTopic(item){
      this.$router.push(`/topic/update/${item.id}`)
    },
    AddActivity(item){
      this.$store.state.pagination.limit = 10
      this.$store.state.pagination.page = 1
      this.$router.push(`/activity/${item.id}`)
    },
    confirmation(id){
      this.$store.state.dialog.confirmDelete = true
      this.id_topic = id
    },
    async deleted(){
      this.$store.state.process.run = true
      await destroy(`api/v1/topic/delete/${this.id_topic}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("#4CAF50", `Topic Deleted Succesfully`);
          // this.getDetailCourse()
          this.getTopicList()
        }else{
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Topic Deleted Failed`);
          // this.getDetailCourse()
          this.getTopicList()
        }
      })
    },
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
  }
}
</script>

<style>

</style>